<template>
  <div class="lottery-history">
    <PageTitle title="抽獎紀錄" hideBtn />
    <!-- 區間選擇 -->
    <section class="filter-option-wrapper">
      <FiltersContainer>
        <MemberGameSearch :model.sync="formData.memberGame" @change="refresh" />
        <RewardSearch
          v-if="formData.memberGame.LotteryId"
          :lotteryId="formData.memberGame.LotteryId"
          :model.sync="formData.rewardName"
          @change="refresh"
        />
      </FiltersContainer>

      <div class="filter-option-container">
        <GroupTimeFilterButton
          :isCustomRange.sync="customerRange"
          @month="getMonthRange"
          @week="getWeekRange"
          @today="getTodayRange"
          @custom="customerRange = true"
        />
        <!-- <el-button-group>
          <el-button
            class="date-range-btn"
            :type="isActived('today')"
            @click="changeTimeRangeType('today')"
          >
            本日
          </el-button>
          <el-button
            class="date-range-btn"
            :type="isActived('week')"
            @click="changeTimeRangeType('week')"
          >
            本週
          </el-button>
          <el-button
            class="date-range-btn"
            :type="isActived('month')"
            @click="changeTimeRangeType('month')"
          >
            本月
          </el-button>
          <el-button
            class="date-range-btn"
            :type="customerRange ? 'primary' : ''"
            @click="changeToCustomer('custom')"
          >
            自定義
          </el-button>
        </el-button-group> -->

        <el-date-picker
          v-if="customerRange"
          v-model="searchTime"
          type="datetimerange"
          format="yyyy-MM-dd HH:mm"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          @change="changeToCustomer(), refresh()"
        />
      </div>
    </section>
    <!-- 統計大字 -->
    <section>
      <div class="statistic-header">
        <div v-if="searchTime" class="range-text">
          |選取期間 {{ dateFormat(searchTime[0]) }} ~
          {{ dateFormat(searchTime[1]) }}
        </div>
        <el-button type="primary" @click="showExportOptions = true">匯出名單</el-button>
      </div>

      <div class="statistic-container">
        <div>
          <p class="title">活動期間（天）</p>
          <p class="value">
            {{ statistical && statistical.day ? statistical.day : '-' }}
          </p>
        </div>
        <el-divider direction="vertical" />
        <div>
          <p class="title">獎項抽出（個）</p>
          <p class="value">
            {{
              statistical && statistical.recordCount !== 0
                ? statistical.recordCount
                : '-'
            }}
          </p>
        </div>
        <el-divider style="height:100%" direction="vertical" />
        <div>
          <p class="title">不重複參與人數（人）</p>
          <p class="value">
            {{
              statistical && statistical.memberCount
                ? statistical.memberCount
                : '-'
            }}
          </p>
        </div>
      </div>
    </section>

    <!-- Table -->
    <el-table v-loading="loading" :data="gameRecord">
      <EmptyBlock slot="empty" />
      <el-table-column label="抽獎名稱" prop="MemberGame.name" align="center" />
      <el-table-column
        label="抽獎類型"
        align="center"
        prop="MemberGame.MemberGameMode.name"
      />
      <el-table-column label="獎項名稱" prop="awardName" align="center" />
      <el-table-column
        label="會員"
        prop="Member.UserInfo.name"
        align="center"
      />
      <el-table-column label="抽獎時間" align="center">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.createdAt) }}
        </template>
      </el-table-column>
    </el-table>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="recordCount"
      @pageChange="refresh"
    />

    <ExportOptionsDialog
      v-if="showExportOptions"
      allRange
      @close="showExportOptions = false"
      @export="showExportOptions = false, prepareExport()"
    />
    <ExportDialog
      v-if="showExportDialog"
      :inProgress="exportting"
      :isError="exportError"
      :percentage="exportPercentage"
      :data="exportData"
      :total="exportCount"
      @close="resetExport"
    />
  </div>
</template>

<script>
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import { checkUserFeature } from '@/store/modules/permission'
import { ExportMoreSheetExcel } from '@/utils/excel'
import {
  GetMemberGameRecord,
  MemberGameRecordStatistical,
  GetMemberGameRecordCount,
} from '@/api/lottery/memberGameRecord'
import tableMixin from '@/mixin/table'
import dayjs from 'dayjs'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import { mapGetters } from 'vuex'
import MemberGameSearch from '@/components/Search/lottery/MemberGameSearch.vue'
import GroupTimeFilterButton from '@/components/Input/GroupTimeFilterButton.vue'

export default {
  name: 'LotteryHistory',
  components: {
    MemberGameSearch,
    GroupTimeFilterButton,
    ExportDialog,
    ExportOptionsDialog,
  },
  mixins: [tableMixin],
  data: () => ({
    showExportOptions: false,
    showExportDialog: false,
    exportting: false,
    exportError: false,
    exportData: null,
    exportCount: 0,
    exportPercentage: 0,

    loading: false,
    timeRangeType: 'today',
    searchTime: ['', ''],
    customerRange: false,
    recordCount: 0,
    gameRecord: [],
    statistical: null,
    formData: {
      memberGame: '',
      rewardName: '',
    },
  }),
  computed: {
    ...mapGetters([
      'shop',
      'userPlanFeature',
      'userFeatures',
    ]),
  },

  async mounted () {
    this.getTodayRange()

    await this.refresh()
  },

  methods: {
    async exportExcel () {
      const [records, err] = await GetMemberGameRecord({
        shopId: this.shop,
        start: 0,
        createdAtStart: this.searchTime[0],
        createdAtEnd: this.searchTime[1],
        MemberGameId: this.formData.memberGame.id,
        LotteryAwardId: this.formData.rewardName.id,
      })
      this.exportCount = records.length
      this.exportData = records

      if (err) return this.$message.error(err)

      const sheetList = [
        {
          sheetName: '抽獎結果',
          data: [],
        },
      ]

      let count = 0

      for (const record of records) {
        sheetList[0].data.push({
          抽獎名稱: record.MemberGame.name,
          抽獎類型: record.MemberGame.MemberGameMode.name,
          獎項名稱: record.awardName,
          會員: record.Member.UserInfo.name,
          抽獎時間: this.dateFormat(record.createdAt),
        })
        count++
      }

      this.exportPercentage = (count / this.exportCount) * 100

      ExportMoreSheetExcel(
        sheetList,
        `抽獎結果 ${this.dateFormat(this.searchTime[0])} ~ ${this.dateFormat(
          this.searchTime[1],
        )}`,
      )
    },

    async prepareExport () {
      const permission = checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.member.exportExcel')
      if (!permission) return this.$message.error('無此權限 admin.member.exportExcel')

      this.exportError = false
      this.showExportDialog = true
      this.exportting = true
      await this.exportExcel()
      this.exportting = false
    },

    resetExport () {
      this.showExportDialog = false
      this.exportting = false
      this.exportError = false
      this.exportData = null
      this.exportPercentage = 0
    },

    async refresh () {
      this.loading = true
      await this.getMemberGameRecord()
      await this.memberGameRecordStatistical()
      await this.getMemberGameRecordCount()
      this.loading = false
    },
    isActived (type) {
      if (this.timeRangeType === type && !this.customerRange) return 'primary'
      return ''
    },
    async getTodayRange () {
      const start = startOfDay(new Date())
      const end = endOfDay(new Date())
      this.searchTime = []
      this.searchTime[0] = start
      this.searchTime[1] = end
      await this.refresh()
    },
    async getWeekRange () {
      const start = startOfWeek(new Date())
      const end = endOfWeek(new Date())
      this.searchTime = []
      this.searchTime[0] = start
      this.searchTime[1] = end
      await this.refresh()
    },
    async getMonthRange () {
      const start = startOfMonth(new Date())
      const end = endOfMonth(new Date())
      this.searchTime = []
      this.searchTime[0] = start
      this.searchTime[1] = end
      await this.refresh()
    },

    async changeToCustomer () {
      this.customerRange = true
      const start = dayjs(this.searchTime[0])
      const end = dayjs(this.searchTime[1])

      if (start.hour() !== 0 || start.minute() !== 0) {
        this.customerRange = true
      } else if (end.hour() !== 23 || end.minute() !== 59) {
        this.customerRange = true
      }
      await this.refresh()
    },

    //= > 改變查詢時間範圍
    async changeTimeRangeType (type) {
      if (type === 'today') {
        this.getTodayRange()
      } else if (type === 'week') {
        this.getWeekRange()
      } else if (type === 'month') {
        this.getMonthRange()
      }

      this.timeRangeType = type
      this.customerRange = false
      await this.refresh()
    },

    async getMemberGameRecord () {
      const [res, err] = await GetMemberGameRecord({
        shopId: this.shop,
        start: this.pageStartIndex,
        limit: this.tableOptions.pageLimit,
        createdAtStart: this.searchTime[0],
        createdAtEnd: this.searchTime[1],
        MemberGameId: this.formData.memberGame.id,
        LotteryAwardId: this.formData.rewardName.id,
      })
      console.log(err)
      console.log(res)
      this.gameRecord = res
    },

    async getMemberGameRecordCount () {
      const [res, err] = await GetMemberGameRecordCount({
        shopId: this.shop,
        createdAtStart: this.searchTime[0],
        createdAtEnd: this.searchTime[1],
        MemberGameId: this.formData.memberGame.id,
        LotteryAwardId: this.formData.rewardName.id,
      })
      console.log(err)
      this.recordCount = res.count
    },

    async memberGameRecordStatistical () {
      const [res, err] = await MemberGameRecordStatistical({
        shopId: this.shop,
        createdAtStart: this.searchTime[0],
        createdAtEnd: this.searchTime[1],
        MemberGameId: this.formData.memberGame.id,
        LotteryAwardId: this.formData.rewardName.id,
        // MemberId: '',
      })
      console.log(err)
      this.statistical = res
    },

  },
}
</script>

<style lang="scss" scoped>
::v-deep .date-range-btn {
  @apply shadow-none;
}
.el-divider--vertical {
  @apply h-auto w-[2px];
}
.filter-option-wrapper {
  @apply flex flex-col gap-[16px];
  .filter-option-container {
    @apply flex gap-[12px] flex-wrap;
  }
}

.statistic-container {
  @apply flex justify-evenly text-center py-[12px];
  @apply bg-white rounded-lg rounded-br-sub;
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.1);

  .title {
    @apply text-gray-100 text-sub;
  }

  .value {
    @apply text-secondary-100 text-[28px] font-bold;
  }
}

.statistic-header {
  @apply flex justify-between items-end pb-[16px];
}
.range-text {
  @apply text-primary-100 font-medium;
}

.date-range-btn {
  @apply px-[28px];
}
</style>
